var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9d55dc0192d69b0ff6a0a5122a1c95a3fc2df435"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/mall";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { encodeId } from '@/utils/utilApi'
import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const processGraphQLBreadcrumb = (breadcrumb, hint) => {
  const requestBody = hint.input
  breadcrumb.message = `GraphQL Request: ${breadcrumb.data.url}`

  if (requestBody) {
    try {
      const requestJson = JSON.parse(requestBody[1]?.body || requestBody)
      enrichBreadcrumbWithGraphQLData(breadcrumb, requestJson)
    } catch (error) {
      console.error('Failed to parse request body:', error)
    }
  }

  return breadcrumb
}

const enrichBreadcrumbWithGraphQLData = (breadcrumb, requestJson) => {
  if (requestJson.query) {
    breadcrumb.data.query = requestJson.query
  }
  if (requestJson.variables) {
    breadcrumb.data.variables = requestJson.variables
  }
  if (requestJson.operationName) {
    breadcrumb.data.operationName = requestJson.operationName
    breadcrumb.data.endcoedOperationName = encodeId('queryName', requestJson.operationName)
  }
}

const adjustDataUrlBreadcrumb = (breadcrumb) => {
  return {
    ...breadcrumb,
    data: {
      ...breadcrumb.data,
      url: breadcrumb.data.url.startsWith('data:') ? breadcrumb.data.url.substr(0, 100) : breadcrumb.data.url,
    },
  }
}

Sentry.init({
  dsn: SENTRY_DSN || 'https://3a78bbfabf2f461fa209e89536e833df@o356934.ingest.sentry.io/5962478',
  // Adjust this value in production, or use tracesSampler for greater control
  environment: process.env.FITPETMALL_ENV,
  tracesSampleRate: 1.0,
  beforeBreadcrumb(breadcrumb, hint) {
    // Process fetch breadcrumbs
    if (breadcrumb.category === 'fetch') {
      if (breadcrumb.data.url.includes('/graphql')) {
        return processGraphQLBreadcrumb(breadcrumb, hint)
      }

      // Adjust data URLs to prevent payload too large errors
      return adjustDataUrlBreadcrumb(breadcrumb)
    }

    return breadcrumb
  },
  beforeSend(event, hint) {
    if (event.exception) {
      const error = hint?.originalException
      if (error?.message?.includes('getInitialProps')) {
        event.fingerprint = ['getInitialProps undefined error']
        event.extra = {
          ...event.extra,
          hintData: hint?.input,
          url: window.location.href,
          hint,
          error,
        }
      }
    }
    return event
  },
  attachStacktrace: true,
  sendDefaultPii: true,
  integrations: [],
  ignoreErrors: [
    // Error generated by a bug in auto-fill library from browser
    // https://github.com/getsentry/sentry/issues/5267
    /Blocked a frame with origin/,
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: Cancelled',
    'TypeError: Load failed',
  ],
})
